import React, { lazy } from 'react';

const Home = lazy(() => import('../pages/index'));
const Timetable = lazy(() => import('../pages/timetable'));
const Login = lazy(() => import('../pages/login'));
const Private = lazy(() => import('../components/Private'));

const routes: any[] = [
	{
		path: '/',
		children: [
			{
				index: true,
				element: <Login />,
			},
			// Comment this and uncomment the other to password protect it
			// {
			// 	path: '/timetable',
			// 	element: <Timetable />,
			// },
		],
	},
	{
		element: <Private />,
		children: [
			{
				path: '/timetable',
				element: <Timetable />,
			},
		],
	},
];

export default routes;
