import React, { Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress, CssBaseline, Stack, ThemeProvider, useTheme } from '@mui/material';
import SnackbarProvider, { useSnackbar } from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from '~/utils/auth';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';

import { faEye as fasEye, faArrowLeft as fasArrowLeft, faCloudUpload as fasCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import {
	faPieChart as fadPieChart,
	faMountainSun as fadMountainSun,
	faServer as fadServer,
	faTablet as fadTablet,
	faSignsPost as fadSignsPost,
	faQrcode as fadQrcode,
	faImages as fadImages,
	faBarsStaggered as fadBarsStaggered,
} from '@fortawesome/pro-duotone-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';

library.add(fasEye, fasArrowLeft, fadPieChart, fadMountainSun, fadServer, fadTablet, fadSignsPost, fadQrcode, fadImages, fasCloudUpload, fadBarsStaggered);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) => !(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: 'always',
		},
		mutations: {
			// Offline support
			networkMode: 'always',
		},
	},
});

const App = () => {
	const [initialising, setInitialising] = useState(true);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense
			fallback={
				<Stack sx={{ height: '100vh', width: '100vw' }} justifyContent="center" alignItems="center">
					<CircularProgress />
				</Stack>
			}
		>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<BrowserRouter>
				<Theme>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<CssBaseline />
									<App />
								</AuthProvider>
							</RoutesProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</Theme>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator) {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
