import { css } from '@emotion/react';
import { Theme } from '@mui/material';

const globalStyles = (theme: Theme) => css`
	@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');
	@import url('https://use.typekit.net/btj3fdv.css');

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html,
	body {
		-webkit-overflow-scrolling: touch;
	}

	body {
		overflow: unset;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	div[role='group'][tabindex] {
		height: 100%;
	}

	button {
		outline: none;
		border: none;
		font-family: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		font-family: ${theme.typography.body1.fontFamily};
	}

	//.wrap-children {
	//	display: flex;
	//	flex-wrap: wrap;
	//}
	//
	//.next-is-wrapped,
	//.next-is-wrapped ~ * {
	//	// [flex-grow vs flex](https://github.com/angular/material/issues/2632)
	//	flex: auto;
	//}
`;

export default globalStyles;
