import React, { createContext, JSXElementConstructor, useContext, useEffect, useLayoutEffect } from 'react';
import { RouteObject } from 'react-router/dist/lib/context';
import { Navigation } from '~/components/Routes';
import { Route } from '~/models/route';

export const DarkModeContext = createContext<{ darkMode: boolean; onToggle: () => void }>({
	darkMode: false,
	onToggle: () => null,
});

export const useDarkMode = () => useContext(DarkModeContext);

export const RoutesContext = createContext<
	Partial<{
		routes: Route[];
		routerRoutes: RouteObject[];
		routeStack: Route[];
		currentRouteItem?: Route | null;
		flatRoutes: Route[];
		navigation: Navigation;
		router: React.ReactElement<any, string | JSXElementConstructor<any>> | null;
	}>
>({
	routes: [],
	routerRoutes: [],
	routeStack: [],
	currentRouteItem: null,
	flatRoutes: [],
	navigation: [],
	router: null,
});

export const useRoutes = () => useContext(RoutesContext);

export const SidebarContext = createContext<
	Partial<{
		open: boolean | null;
		minimised: boolean;
		onTemporaryMinimise: () => void;
		onMinimiseToggle: () => void;
		onToggle: () => void;
		mobileOpen: boolean;
		onMobileToggle: () => void;
	}>
>({});

export const useSidebar = () => useContext(SidebarContext);

export const LayoutContext = createContext<{ $toolbar: HTMLElement | null }>({ $toolbar: null });
export const useLayout = () => useContext(LayoutContext);
