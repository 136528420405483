import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo } from 'react';
import { RoutesContext } from '~/utils/contexts';
import { RouteObject } from 'react-router/dist/lib/context';
import { useLocation, useMatch } from 'react-router-dom';
import { PreRoute, Route } from '~/models/route';
import { useRoutes, generatePath, matchRoutes } from 'react-router-dom';

const routeMatches = (route: Route | undefined) => {
	if (!route) return false;
	if (route?.match) return true;
	if (route?.children?.some(routeMatches)) return true;
};

const useFlatRoutes = (routes: Route[]): Route[] => {
	return useMemo(() => {
		const flattenNavigationItems = (items: Route[] = routes): Route[] => {
			const flattenNavigationItem = (item: Route) => {
				// to make TS happy
				if (!item) return [];
				if (!item?.children?.length) return [item];

				return [item, ...flattenNavigationItems(item.children)];
			};

			return items.reduce((acc: Route[], curr) => [...acc, ...flattenNavigationItem(curr)], []);
		};

		return flattenNavigationItems();
	}, []);
};

const useRouteStack = (routes: Route[]) => {
	return useMemo(() => {
		const createRouteStack = (items: Route[] = routes): any => {
			const routeStack = items?.find((item) => routeMatches(item));

			if (routeStack?.children) {
				return [routeStack, ...createRouteStack(routeStack?.children)]?.filter((x) => !!x);
			}

			if (!!routeStack) return [routeStack];

			return [];
		};

		return createRouteStack();
	}, [routes]);
};

const convert = (item: PreRoute, parentPath?: string | null, parent?: PreRoute): Route => {
	const currentPath = item?.index ? parent?.path : item?.path;
	const path = (parentPath ? `${parentPath}${currentPath ?? ''}` : currentPath ?? '') ?? '';
	const checkMatch = (route: PreRoute, recursive = false): boolean => {
		const match = !!route?.path ? !!useMatch(route.path) : false;

		return match || ((recursive && item?.children?.some((route) => !!route && checkMatch(route))) ?? false);
	};

	return {
		...item,
		path,
		match: checkMatch(item),
		rootMatch: checkMatch(item, true),
		children: !!item?.children?.length ? item.children.map((x) => convert(x, parentPath, parent)) : undefined,
	};
};

const routerConvert = (item: PreRoute): RouteObject => {
	const route = { ...item };
	delete route.name;
	delete route.sidebar;
	if (route.children) route.children.map(routerConvert);
	return route;
};

const useRouter = (routes: RouteObject[]) => useRoutes(routes);

export type Navigation = {
	name: string;
	path: string;
	icon: React.ReactNode;
	match: boolean;
}[];

const RoutesProvider: React.FC<{ routes: PreRoute[]; children: React.ReactNode }> = ({ children, routes: _routes }) => {
	const { currentRouteItem: parentRouteItem } = useContext(RoutesContext);
	const parent = !!parentRouteItem?.path ? generatePath(parentRouteItem?.path) : null;
	const location = useLocation();

	const navigation = [
		{
			name: 'Dashboard',
			path: '/dashboard',
			icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
		},
		{
			name: 'Experiences',
			path: '/dashboard/experiences',
			icon: <FontAwesomeIcon icon={['fad', 'mountain-sun']} />,
		},
		{
			name: 'Deployment',
			path: '/dashboard/deployments',
			icon: <FontAwesomeIcon icon={['fad', 'server']} />,
		},
		{
			name: 'Devices',
			path: '/dashboard/devices',
			icon: <FontAwesomeIcon icon={['fad', 'tablet']} />,
		},
		{
			name: 'Locations',
			path: '/dashboard/locations',
			icon: <FontAwesomeIcon icon={['fad', 'signs-post']} />,
		},
		{
			name: 'Links',
			path: '/dashboard/links',
			icon: <FontAwesomeIcon icon={['fad', 'qrcode']} />,
		},
		{
			name: 'Assets',
			path: '/dashboard/assets',
			icon: <FontAwesomeIcon icon={['fad', 'images']} />,
		},
	].map((x) => convert(x)) as Navigation;

	const routes = _routes.map((x) => convert(x, parent));
	const routerRoutes = _routes.map(routerConvert);
	const flatRoutes = useFlatRoutes(routes);
	const routeStack = useRouteStack(routes);
	const currentRouteItem = routeStack?.[routeStack?.length - 1];
	const router = useRouter(routerRoutes);

	return (
		<RoutesContext.Provider
			value={{
				navigation,
				routes,
				routerRoutes,
				flatRoutes,
				routeStack,
				router,
				currentRouteItem,
			}}
		>
			{children}
		</RoutesContext.Provider>
	);
};

export default RoutesProvider;
